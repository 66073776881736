<template>
<div class="station-alarm-container">
  <div class="device-data-area">
    <div class="scan-input-area" v-if="scanning && devices.length < maxCount">
      <span class="scan-input-label">通信ID：</span>
      <Input id="taskNameInput" ref="taskNameInput" type="text" v-model="scanCode" autofocus @on-blur="onLostFocus" @on-enter="inputEnter"></Input>
      <Button id="taskNameButton" size="default" type="error" @click="stopScan">录入完成，开始测试</Button>
    </div>
    <div class="alarm-data-query">
      <Button size="default" type="primary" @click="startScan" style="margin-right: 10px;">扫码录入</Button>
      <span>一路电压标准值：</span>
      <Input type="number" v-model="voltageLevel1" style="width: 100px; margin-right: 10px;"><span slot="append">V</span></Input>
      <span class="ml10">电压误差率：</span>
      <Input type="number" v-model="voltageRate1" style="width: 100px; margin-right: 10px;"><span slot="append">%</span></Input>
      <span class="ml10">电流标准值：</span>
      <Input type="number" v-model="currentLevel1" style="width: 100px; margin-right: 10px;"><span slot="append">A</span></Input>
      <span class="ml10">电流误差率：</span>
      <Input type="number" v-model="currentRate1" style="width: 100px; margin-right: 10px;"><span slot="append">%</span></Input>
      <span class="ml10">功率标准值：</span>
      <Input type="number" v-model="powerLevel1" style="width: 100px; margin-right: 10px;"><span slot="append">W</span></Input>
      <span class="ml10">功率误差率：</span>
      <Input type="number" v-model="powerRate1" style="width: 100px; margin-right: 10px;"><span slot="append">%</span></Input>
      <span class="test-count-area">测试数量：{{ devices.length }}</span>
      <Button size="default" type="primary" @click="initList" style="margin-right: 10px;">刷新数据</Button>
      <Button size="default" type="error" @click="deleteDevice" style="margin-right: 10px;">删除设备</Button>
    </div>
    <div class="alarm-data-query">
      <Button size="default" type="warning" @click="saveToCust" style="margin-right: 10px;">成品发货</Button>
      <span>二路电压标准值：</span>
      <Input type="number" v-model="voltageLevel2" style="width: 100px; margin-right: 10px;"><span slot="append">V</span></Input>
      <span class="ml10">电压误差率：</span>
      <Input type="number" v-model="voltageRate2" style="width: 100px; margin-right: 10px;"><span slot="append">%</span></Input>
      <span class="ml10">电流标准值：</span>
      <Input type="number" v-model="currentLevel2" style="width: 100px; margin-right: 10px;"><span slot="append">A</span></Input>
      <span class="ml10">电流误差率：</span>
      <Input type="number" v-model="currentRate2" style="width: 100px; margin-right: 10px;"><span slot="append">%</span></Input>
      <span class="ml10">功率标准值：</span>
      <Input type="number" v-model="powerLevel2" style="width: 100px; margin-right: 10px;"><span slot="append">W</span></Input>
      <span class="ml10">功率误差率：</span>
      <Input type="number" v-model="powerRate2" style="width: 100px; margin-right: 10px;"><span slot="append">%</span></Input>
      <span class="test-count-area">成功数量：{{ devices.filter(p => p._status == 9).length }}</span>
      <Button size="default" type="success" @click="saveToStore" style="margin-right: 10px;">备品入库</Button>
      <span class="save-store-area">只有确定作为备品才入库，其他均为成品发货</span>
    </div>
    <div v-if="commands.length > 0" class="alarm-data-query">
      <template v-for="(cmd, idx) in commands">
        <Button :key="idx" type="info" size="default" style="margin-right: 10px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
      </template>
      <Button type="success" size="default" style="margin-right: 10px" @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      当前组织：<span style="font-weight: bolder; font-size: 20px;">{{ cust.name }}[{{ cust.id }}]</span>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" stripe round
        @checkbox-change="selectChangeEvent" :data="devices" height="auto" :row-config="{isHover: true, height: rowHeight}" :loading="loading"
        :checkbox-config="{checkField: 'checked',}">
        <vxe-column type="checkbox" width="60" fixed="left"></vxe-column>
        <vxe-column type="seq" width="60" fixed="left"></vxe-column>
        <!-- <vxe-column field="name" :title="showLang('com.tab.title')" fixed="left" width="150" sortable header-align="center"></vxe-column> -->
        <vxe-column field="code" :title="showLang('com.export.cn.id')" fixed="left" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="cust" title="所在组织" fixed="left" width="150">
          <template #default="params">
            <span :style="{ color: params.row.custId == cust.id ? 'darkgreen' : 'red' }">{{ params.row.custName }}[{{ params.row.custId }}]</span>
          </template>
        </vxe-column>
        <vxe-column field="online" :title="showLang('com.state')" fixed="left" width="50">
          <template #default="params">
            <img :src="getStateImage(params)" />
          </template>
        </vxe-column>
        <vxe-column title="通道号" width="100" header-align="center">
          <template>
            <div class="split-row-area">
              <div :class="['split-row-item', 'split-row-flag']">通道一</div>
              <div class="split-row-item">通道二</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="output1" title="亮度(%)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div :class="['split-row-item', 'split-row-flag']">{{ params.row.bright1 }}</div>
              <div class="split-row-item">{{ params.row.bright2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="color1" title="色温(%)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div :class="['split-row-item', 'split-row-flag']">{{ params.row.color1 }}</div>
              <div class="split-row-item">{{ params.row.color2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="voltage" title="电压(V)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div :class="['split-row-item', 'split-row-flag', voltageError(params, 1) ? 'alarm-data-flag' : '']">{{ params.row.voltage1 }}</div>
              <div :class="['split-row-item', voltageError(params, 2) ? 'alarm-data-flag' : '']">{{ params.row.voltage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="current" title="电流(A)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div :class="['split-row-item', 'split-row-flag', currentError(params, 1) ? 'alarm-data-flag' : '']">{{ params.row.current1 }}</div>
              <div :class="['split-row-item', currentError(params, 2) ? 'alarm-data-flag' : '']">{{ params.row.current2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="power2" title="功率(W)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div :class="['split-row-item', 'split-row-flag', powerError(params, 1) ? 'alarm-data-flag' : '']">{{ params.row.power1 }}</div>
              <div :class="['split-row-item', powerError(params, 2) ? 'alarm-data-flag' : '']">{{ params.row.power2 }}</div>
            </div>
          </template>
        </vxe-column>
        <!-- <vxe-column field="powerOn1" title="累计亮灯(分)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.powerOn1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.powerOn2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="quality1" title="累计能耗(W)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.quality1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.quality2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="voltageLeakage1" title="漏电电压(V)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.voltageLeakage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.voltageLeakage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="currentLeakage1" title="漏电电流(mA)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.currentLeakage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.currentLeakage2 }}</div>
            </div>
          </template>
        </vxe-column> -->
        <!-- <vxe-column field="rssi" title="信号强度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="tempChip" title="芯片温度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="tempModule" title="模块温度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="batterySun" title="电池电压" width="100" sortable header-align="center"></vxe-column> -->
        <vxe-column field="fireTimeString" title="状态上报时间" width="150" sortable header-align="center" fixed="left"></vxe-column>
        <vxe-column field="_message" :title="showLang('com.ins.result')" header-align="center" sortable width="300" fixed="right"></vxe-column>
        <!-- <vxe-column field="groupName" title="所在分区" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="deviceTypeName" title="设备类型" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="gatewayName" title="关联网关" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="gatewayCode" title="网关ID" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="channel" title="上行通道" width="100" sortable header-align="center"></vxe-column> -->
        <vxe-column field="iccid" title="ICCID" width="180" sortable header-align="center"></vxe-column>
        <vxe-column field="imei" title="IMEI" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="hardware" title="硬件版本" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="software" title="软件版本" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="appcode" title="应用编号" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="version" title="应用版本" width="200" sortable header-align="center"></vxe-column>
      </vxe-table>
    </div>
    <ModalCheckInfo v-model="showCheckModal" :item="statusInfo" @saved="initList" />
    <ModalComment v-model="showCommentModal" :item="commentItem" @saved="commentSaved" />
    <ModalArgEdit v-model="showArgsModal" :command="commandItem" @saved="argsSaved" />
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalCheckInfo from './ModalCheckInfo'
import ModalComment from './ModalComment'
import ModalArgEdit from './ModalArgEdit'
// import exportXlsx from '@/components/xlsx/exportXlsx'
// import GroupTree from '../../../common/treeGroup/Index'
export default {
  name: 'CommandChannelIndex',
  components: {
    ModalCheckInfo,
    ModalComment,
    ModalArgEdit,
    // ModalCheckAuth,
    // GroupTree,
  },
  props: {
  },
  data() {
    return {
      loading: false,
      rowHeight: 60,
      maxCount: 100,
      scanning: false,
      voltageLevel1: 220,
      voltageRate1: 10,
      currentLevel1: 0.1,
      currentRate1: 10,
      powerLevel1: 10,
      powerRate1: 10,
      voltageLevel2: 220,
      voltageRate2: 10,
      currentLevel2: 0.1,
      currentRate2: 10,
      powerLevel2: 10,
      powerRate2: 10,
      scanCode: '请扫码',
      devices: [],
      showCheckModal: false,
      statusInfo: {},
      showCommentModal: false,
      commentItem: {},
      commands: [{
        code: "forceRead",
        name: "召测数据",
        args: {},
      },{
        code: "handSingle",
        name: "开灯",
        args: {bright1: 100, bright2: 100, color1: 100, color2: 100, expire: 10000},
      },{
        code: "handSingle",
        name: "关灯",
        args: {bright1: 0, bright2: 0, color1: 0, color2: 0, expire: 10000},
      },{
        code: "handSingle",
        name: "调光30/60",
        args: {bright1: 30, bright2: 60, color1: 30, color2: 60, expire: 10000},
      },{
        code: "getInfo",
        name: "设备信息",
        args: {},
      },{
        code: "getSimInfo",
        name: "卡信息",
        args: {},
      },{
        code: "getNetwork",
        name: "查询网络",
        args: {},
      },{
        code: "setNetwork",
        name: "设置网络",
        args: {},
      }],
      commandItem: {},
      showArgsModal: false,
      
      dataRefresh: 0,
      cmdResults: {},
      timer: null,
      cust: {id: 0, name: ''},
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('auth', ['config']),
    ...mapState('common', ['pageLayouts', 'pageSizes', 'coms6038names']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
  },
  mounted: function () {
    this.initList();
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      // console.log('light cmd coming', params);
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    });
    window.eventBus.$on('lampRealDataComing', params => {
      let lamps = this.devices.filter(p => p.id == params.id);
      for(let lamp of lamps){
        for(let key in params.data){
          this.$set(lamp, key, params.data[key])
        }
      }
    });
  },
  destroyed: function () {
    window.eventBus.$off('lampRealDataComing');
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    deleteDevice: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要控制的单灯");
        return;
      }
      let list = chks.map(p => p.code);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`site/debug/DeleteTestLightDevice`, { list: list }).then(res => {
            if (res.code == 0) {
              this.initList();
            }
          });
        }
      });
    },
    commentSaved: function(params){
      // console.log('save to store', params);
      let url = `site/debug/${params.cmd}`;
      this.$axios.post(url, { codes: params.codes, comment: params.comment }).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功');
          this.initList();
        }
      })
    },
    saveToStore: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning("请选择要入库的设备");
        return;
      }
      let codes = chks.map(p => p.code);
      this.commentItem = {cmd: 'saveToStore', title: '保存入库', codes};
      this.showCommentModal = true;
    },
    saveToCust: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning("请选择要发货的设备");
        return;
      }
      let codes = chks.map(p => p.code);
      this.commentItem = {cmd: 'saveToCust', title: '保存发货', codes};
      this.showCommentModal = true;
    },
    voltageError: function(params, ch){
      if(params.row[`bright${ch}`] != 100)return false;
      let v = params.row[`voltage${ch}`];
      if(ch == 1){
        return Math.abs((v - this.voltageLevel1) *100 / this.voltageLevel1) > this.voltageRate1;
      }else{
        return Math.abs((v - this.voltageLevel2) *100 / this.voltageLevel2) > this.voltageRate2;
      }
    },
    currentError: function(params, ch){
      if(params.row[`bright${ch}`] != 100)return false;
      let v = params.row[`current${ch}`];
      if(ch == 1){
        return Math.abs((v - this.currentLevel1) *100 / this.currentLevel1) > this.currentRate1;
      }else{
        return Math.abs((v - this.currentLevel2) *100 / this.currentLevel2) > this.currentRate2;
      }
    },
    powerError: function(params, ch){
      if(params.row[`bright${ch}`] != 100)return false;
      let v = params.row[`power${ch}`];
      if(ch == 1){
        return Math.abs((v - this.powerLevel1) *100 / this.powerLevel1) > this.powerRate1;
      }else{
        return Math.abs((v - this.powerLevel2) *100 / this.powerLevel2) > this.powerRate2;
      }
    },
    startScan: function(){
      if(this.devices.length >= this.maxCount){
        this.$Message.warning(`最多只能测试${this.maxCount}个设备`);
        return;
      }
      this.scanning = true;
      setTimeout(() => {
        this.$refs.taskNameInput.focus({cursor: 'all'});
        document.querySelector("#taskNameInput .ivu-input").select();
      }, 100);
    },
    stopScan: function(){
      this.scanning = false;
    },
    onLostFocus: function(e){
      // console.log('lost focus', e);
      e.target.focus({cursor: 'all'});
      document.querySelector("#taskNameInput .ivu-input").select();
    },
    inputEnter: function(){
      // console.log('input enter', e);
      document.querySelector("#taskNameInput .ivu-input").select();
      console.log('get code', this.scanCode);
      this.getDeviceInfo();
    },
    initList: function () {
      if(this.loading)return;
      this.loading = true;
      this.$axios.post(`site/debug/QueryTestLightDevice`, {}).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'devices', res.data.list);
          this.$set(this, 'cust', {id: res.data.custId, name: res.data.custName});
          if(this.scanning && this.scanCode.length > 5){
            let rows = this.devices.filter(p => p.code == this.scanCode);
            this.$refs.chkTable.setCheckboxRow(rows, true);
          }
        }
      });
    },
    getDeviceInfo: function () {
      if(this.loading)return;
      this.loading = true;
      this.$axios.post(`site/debug/GetLightInfo`, {code: this.scanCode}).then(res => {
        this.loading = false;
        if (res.code !== 0) return;
        if(res.data.status == 1){
          this.initList();
        }else{
          this.$set(this, 'statusInfo', res.data);
          this.showCheckModal = true;
        }
      });
    },
    getStateImage: function(params){
      if(!params.row.online){
        return this.img.tree.station.offliner
      }else{
        if(params.row.bright1 > 0 || params.row.bright2 > 0){
          return this.img.tree.station.onlineOn
        }else{
          return this.img.tree.station.onlineOff
        }
      }
    },
    setCmdResult: function(){
      for(let cmdId in this.cmdResults){
        let ds = this.devices.filter(p => p._cmdId == cmdId);
        if(ds.length == 0){
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            console.log('cmd id time out');
            delete this.cmdResults[cmdId];
          }
          continue;
        }else{
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], '_status', item.status);
          this.$set(ds[0], '_result', item.message);
          this.$set(ds[0], '_content', item.content);
          this.$set(ds[0], '_message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      // this.calcSuccessRate();
    },
    calcSuccessRate: function(){
      let total = this.devices.length;
      let success = this.devices.filter(p => p.status == 9).length;
      let rate = 0;
      if(total <= 0){
        rate = 0;
      }else{
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', {total, success, rate });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      let code = '';
      let args = {};
      for (let d of this.devices) {
        if ((d._status == 7 || d._status == 3 || d._status == 8) && chks.filter(p => p.code == d.code).length > 0) {
          this.$set(d, '_status', 0)
          this.$set(d, '_result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, '_message', this.getCmdStatus(d))
          cmds.push(d.code);
          code = d._cmd;
          args = d._args;
        } else if (this.clearOldLog) {
          this.$set(d, '_status', 0)
          this.$set(d, '_result', '')
        }
      }
      let cmd = {
        code: code,
        list: cmds,
        args: args
      };
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    clickCmd: function (params) {
      // console.log('click cmd', params);
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要控制的单灯");
        return;
      }
      if(params.code == 'setNetwork'){
        this.$set(this, 'commandItem', params);
        this.showArgsModal = true;
        return
      }
      let list = chks.map(p => p.code);
      let cmds = {
        code: params.code,
        list: list,
        args: params.args
      };
      this.sendCommand(cmds);
    },
    argsSaved: function(params){
      // console.log('click cmd', params);
      this.showArgsModal = false;
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要控制的单灯");
        return;
      }
      let list = chks.map(p => p.code);
      let cmds = {
        code: params.code,
        list: list,
        args: params.args
      };
      this.sendCommand(cmds);
    },
    getCmdStatus: function (cmd) {
      switch (cmd._status) {
        case 0: return `${cmd._result}`;
        case 2: return this.showLang('cmd.has.sent') + this.showLang('com.ins.dev.reply');
        case 3: return cmd._result;
        case 4: return this.showLang('com.ins.execution') + `：${cmd._content.pkg} / ${cmd._content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd._result}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail') + `：${cmd._result}`;
        case 9:
            // console.log('get cmd result', cmd);
          switch (cmd._cmd) {
            case 'forceRead': {
              this.$set(cmd, 'bright1', cmd._content.outRate1);
              this.$set(cmd, 'bright2', cmd._content.outRate2);
              this.$set(cmd, 'color1', cmd._content.outColor1);
              this.$set(cmd, 'color2', cmd._content.outColor2);
              if(!cmd._content.voltage1)cmd._content.voltage1 = cmd._content.voltage;
              this.$set(cmd, 'voltage1', cmd._content.voltage1);
              this.$set(cmd, 'voltage2', cmd._content.voltag2 || 0);
              this.$set(cmd, 'power1', cmd._content.power1);
              this.$set(cmd, 'power2', cmd._content.power2);
              this.$set(cmd, 'current1', cmd._content.current1);
              this.$set(cmd, 'current2', cmd._content.current2);
              return this.showLang('com.ins.success');
            }
            case 'getStationInfo': {
              return `curr_rat：${cmd._content.currRat}，dlBler：${cmd._content.dlBler}，UpBler：${cmd._content.UpBler}，RSRP：${cmd._content.rsrp}，RSRQ：${cmd._content.rsrq}`;
            }
            case 'getSigFlag': return +`${this.showLang('com.ins.success')}:${this.showLang('com.op.open')}：${cmd._content.on}，${this.showLang('com.op.shut')}：${cmd._content.off}`;
            case 'getAPN': return `APN:${cmd._content.apn}, user: ${cmd._content.user}, pswd: ${cmd._content.pswd}`;
            case 'getclock': return this.showLang('com.ins.success')+`：${cmd._content.nowTime}`;
            case 'getGroup': return this.showLang('com.ins.success')+`：${this.showLang('com.group.number')} ${cmd._content.no}`;
            case 'getInfo':{
              let result = '';
              if(cmd._content.appcode){
                result += `应用编号：${cmd._content.appcode}；`
              }
              if(cmd._content.appversion){
                result += `应用版本：${cmd._content.appversion}；`
              }
              if(cmd._content.hardware){
                result += `硬件版本：${cmd._content.hardware}；`
              }
              if(cmd._content.software){
                result += `软件版本：${cmd._content.software}；`
              }
              if(cmd._content.manufacture){
                result += `生产厂商：${cmd._content.manufacture}；`
              }
              this.$set(cmd, 'appCode', cmd._content.appcode);
              this.$set(cmd, 'appVersion', cmd._content.appversion);
              this.$set(cmd, 'hardware', cmd._content.hardware);
              this.$set(cmd, 'software', cmd._content.software);
              return result;
              // this.showLang('com.site.hw')+`：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture || '-'}`;
            }
            case 'getSimInfo':{
              this.$set(cmd, 'iccid', cmd._content.iccid);
              this.$set(cmd, 'imei', cmd._content.imei);
              return `ICCID：${cmd._content.iccid}，IMEI：${cmd._content.imei}，RSSI：${cmd._content.rssi}`;
            }
            case 'getChipVolCurArgs': return this.showLang('cmd.result.chip.vol.cur', cmd._content.vol1, cmd._content.vol2, cmd._content.leakVol, cmd._content.cur1, cmd._content.cur2, cmd._content.leakCur);
            case 'getDayPlan1': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd._content[n];
                if (!item) continue;
                str += this.showLang('com.date.period')+`${n + 1}，`;
                for (let c = 0; c < 2; c++) {
                  let time = item[`min${c+1}`]
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if(time != undefined){
                    str += `${c + 1}通道${this.showLang('com.date.time')}：${time}，`;
                  }
                  if (bright != undefined) {
                    str += `亮度：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `色温：${color}，`;
                  }
                }
                str += "\n";
                // str += `时段${n+1}：时间：${item.min}分,1通道亮度：${item.ch1}，1通道色温：${item.c1},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};`
              }
              return str;
            }
            case 'getDayPlan2': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd._content[n];
                if (!item) continue;
                str += this.showLang('com.date.period')+`${n + 1}，`;
                for (let c = 0; c < 4; c++) {
                  let time = item[`min${c+1}`]
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if(time != undefined){
                    str += `${c + 1}通道${this.showLang('com.date.time')}：${time}，`;
                  }
                  if (bright != undefined) {
                   str += `亮度：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `色温：${color}，`;
                  }
                }
                str += "\n";
                // str += `时段${n}：${cmd.content[`enable1${n}`] ? '启用' : '禁用'}；时间：${cmd.content[`start1${n}`]},1通道亮度：${cmd.content[`bright1${n}`]}，1通道色温：${cmd.content[`color1${n}`]},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};`
              }
              return str;
            }
            case 'getPlanType': {
              switch (cmd._content.type) {
                case 0: return this.showLang('com.but.timing');
                case 1: return this.showLang('com.but.ontime');
                case 2: return this.showLang('com.but.mode.light');
                case 3: return this.showLang('com.but.mode.sigle');
                case 255: return this.showLang('com.but.day.table.no');
                default: return this.showLang('com.but.timing');
              }
            }
            case 'getNetwork': return `IP:${cmd._content.host}, ${this.showLang('com.ins.port')}:${cmd._content.port}, ${this.showLang('com.ins.heartbeat')}:${cmd._content.heart}s`
            case 'getLuxOpenValue': return `${this.showLang('com.ins.light.val')}:${cmd._content.lux}`
            case 'getAlarmStatus': {
              return `${this.showLang('com.lighting.V.alarm')}:${cmd._content.voltage ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.lighting.I.alarm')}：${cmd._content.current ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.lighting.T.alarm')}：${cmd._content.temp ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.gyro.police')}：${cmd._content.gyro ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.leac.police')}：${cmd._content.leac ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.leav.police')}：${cmd._content.leav ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}`
            }
            case 'getLeakageAlarmLevel': {
              return `${this.showLang('com.stat.level1')}：${cmd._content.l1}; ${this.showLang('com.stat.level2')}：${cmd._content.l2}; ${this.showLang('com.stat.level3')}：${cmd._content.l3}`;
            }
            case 'getLeakageAlarmEnable': {
              return `${this.showLang('com.state')}：${cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}; ${this.showLang('com.date.check')}：${cmd._content.check}; ${this.showLang('com.ins.record.num')}：${cmd._content.count}`;
            }
            case 'getLeakageAlarmStatus': {
              return `${this.showLang('com.state')}：${this.leakagAlarmeStatus[cmd._content.level]}; ${this.showLang('com.ins.cl.val')}：${cmd._content.val};`;
            }
            case 'getUnionParam': {
              return `
              <p>${this.showLang('com.lighting.delay.lamp1')}：${cmd._content.keep1}s</p>
              <p>${this.showLang('com.lighting.delay.lamp2')}：${cmd._content.keep2}s</p>
              <p>${this.showLang('com.lighting.is.sensing')}：${cmd._content.sensor == 1 ?this.showLang('com.state.effective'):this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.is.signal')}：${cmd._content.txen > 10 ? this.showLang('com.state.effective') :this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.send.cycle')}：${cmd._content.period}s</p>
              <p>${this.showLang('com.lighting.linkage.number')}：${cmd._content.rfsn}；</p>
              <p>433${this.showLang('com.lighting.send.power')}：${cmd._content.txpower}；</p>
              <p>433${this.showLang('com.lighting.channel')}：${cmd._content.channel}；</p>
              <p>${this.showLang('com.lighting.response.range')}：${cmd._content.range}；</p>
              <p>${this.showLang('com.lighting.is.put.signal')}：${cmd._content.rxen == 1 ?this.showLang('com.state.receive') : this.showLang('com.state.no.receive') }；</p>
              <p>${this.showLang('com.lighting.linkage.mode')}：${cmd._content.mode == 1 ? this.showLang('com.lighting.Linkage.control') : this.showLang('com.lighting.lamp.contact')}；</p>
              <p>${this.showLang('com.lighting.repeat.num')}：${cmd._content.repeat}；</p>
              `
            }
            case 'get433Args': {
              // console.log('get 433 args 1', cmd)
              return `
              1路亮灯延时：${cmd._content.delay1}s
              2路亮灯延时：${cmd._content.delay2}s
              是否允许传感器检测：${cmd._content.senseEn == 1 ?this.showLang('com.state.effective'):this.showLang('com.state.invalid')}；
              检测灵敏度：${cmd._content.senseLevel}s
              检测到后是否允许发送：${cmd._content.txReqEn == 1 ?this.showLang('com.state.effective'):this.showLang('com.state.invalid')};
              检测到后发送数量：${cmd._content.txReqCnt}；
              发送周期：${cmd._content.txDuty}；
              发送不响应期：${cmd._content.txSleep}；
              是否允许接收信号：${cmd._content.rxReqEn == 1 ?this.showLang('com.state.receive') : this.showLang('com.state.no.receive')}；
              发送功率：${cmd._content.txPower}；
              信道：${cmd._content.channel}；
              控制的范围：${cmd._content.range}；
              `
            }
            case 'get433Plan': {
              // console.log('get 433 args 1', cmd)
              return `
              一路亮度1：${cmd._content.bright11};
              一路色温1：${cmd._content.color11};
              一路亮度2：${cmd._content.bright12};
              一路色温2：${cmd._content.color12};
              一路亮度3：${cmd._content.bright13};
              一路色温3：${cmd._content.color13};
              一路亮度4：${cmd._content.bright14};
              一路色温4：${cmd._content.color14};
              一路亮度5：${cmd._content.bright15};
              一路色温5：${cmd._content.color15};
              一路亮度6：${cmd._content.bright16};
              一路色温6：${cmd._content.color16};
              二路亮度1：${cmd._content.bright21};
              二路色温1：${cmd._content.color21};
              二路亮度2：${cmd._content.bright22};
              二路色温2：${cmd._content.color22};
              二路亮度3：${cmd._content.bright23};
              二路色温3：${cmd._content.color23};
              二路亮度4：${cmd._content.bright24};
              二路色温4：${cmd._content.color24};
              二路亮度5：${cmd._content.bright25};
              二路色温5：${cmd._content.color25};
              二路亮度6：${cmd._content.bright26};
              二路色温6：${cmd._content.color26};
              `
            }
            case 'get433Id': {
              return `ID: ${cmd._content.id}`;
            }
            case 'getSNID': {
              return `SNID: ${cmd._content.snid}`;
            }
            case 'getRelayLevel': {
              return `${cmd._content.level}`;
            }
            case 'getAlarmRange': {
              return this.showLang('cmd.msg.alarm.range', cmd._content.interval, cmd._content.vLow, cmd._content.vHigh, cmd._content.cLow, cmd._content.cHigh, cmd._content.tLow, cmd._content.tHigh, cmd._content.leac, cmd._content.leav);
              // return `间隔时间：${cmd.content.interval}分，电压范围：${cmd.content.vLow} - ${cmd.content.vHigh} V，电流范围：${cmd.content.cLow} - ${cmd.content.cHigh} A，温度范围：${cmd.content.tLow} - ${cmd.content.tHigh} ℃`
            }
            case 'getAdjustParams': return `${this.showLang('com.lighting.V.coefficient')}:${cmd._content.u}, ${this.showLang('com.lighting.I.coefficient')}:${cmd._content.i}, ${this.showLang('com.lighting.P.coefficient')}:${cmd._content.p}`;
            case 'getLatLng': return `${this.showLang('com.but.gis.get.result', cmd._content.lng, cmd._content.lat)}`;
            case 'getLatLngArgs': {
              let result = this.showLang('com.ins.ch') + cmd._content.channel + ',';
              result += (cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')) + ',\n';
              let i = 1;
              for(let item of cmd._content.list){
                result += this.showLang('com.date.time') + i + ', ' 
                  + this.showLang('com.lighting.op') + ': ' + item.bright + ', ' 
                  + this.showLang('com.lighting.oc') + ': ' + item.color + ','
                  + this.showLang('com.date.delayed') + ': ' + item.delay + this.showLang('unit.time.min') + '; \n';
                i++;
              }
              return `${this.showLang('com.but.gis.args.get.result', result)}`;
            }
            case 'getVoltageOutputArgs': {
              return this.getEnumName('voltageOutputArgs', cmd._content.flag)
            }
            case 'getLoraWork': {
              return `信道:${cmd._content.ch}，速率：${cmd._content.frq}`;
            }
            default: return this.showLang('com.ins.success');
          }
      }
    },
    sendCommand: function(cmd){
      if(this.loading)return;
      if(cmd.list.length == 0)return;
      this.$set(this, 'cmdResults', {});
      for (let d of this.devices) {
        if(cmd.list.includes(d.code)){
          this.$set(d, '_message', '')
        }
      }
      setTimeout(() => {
        this.loading = true;
        this.$axios.post(`site/debug/SendLightCommand`, cmd).then(res => {
          this.loading = false;
          if (res.code == 0) {
            for (let item of res.data.list) {
              let ds = this.devices.filter(p => p.code == item.code);
              if (ds.length == 0) continue;
              this.$set(ds[0], '_cmd', cmd.code);
              this.$set(ds[0], '_args', cmd.args);
              this.$set(ds[0], '_cmdId', item.success ? item.message : '');
              this.$set(ds[0], '_status', item.success ? 1 : 8);
              this.$set(ds[0], '_content', {});
              this.$set(ds[0], '_result', '');
              this.$set(ds[0], '_message', item.success ? '指令已进入发送队列' : item.message);
            }
          }
        });
      }, 100);
    },
  }
}
</script>
<style scoped>
.save-store-area{
  border: solid 1px red;
  padding: 3px;
  color: red;
  font-weight: bolder;
  width: 150px;
  text-wrap: wrap;
}
.station-alarm-container {
  height: 100%;
  display: flex;
  /* flex-direction: column;
  align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
}
.scan-input-area{
  position: absolute;
  border: solid 1px #dcdee2;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  height: 135px;
  z-index: 1000;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 50px;
}
#taskNameInput{
  width: 450px;
  height: 80px;
  /* margin: auto; */
}
#taskNameButton{
  width: 500px;
  height: 80px;
  font-size: 50px;
}
#taskNameInput >>> input{
  width: 400px;
  height: 80px;
  font-size: 80px;
  text-align: center;
  /* background-color: blue; */
  /* color: white; */
}
.scan-input-label{
  font-size: 50px;
}
.test-count-area{
  font-weight: bolder;
  font-size: 20px;
  margin-right: 10px;
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
  padding: 0 5px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.alarm-data-flag{
  color: red;
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>